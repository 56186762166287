@import url("./assets/lib/hljs/styles/atom-one-light.css");
@import url("../node_modules/ng-zorro-antd/src/ng-zorro-antd.min.css");
@import "./assets/scss/app.scss";


.page-heading {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.app-black-button,
.app-black-button:active,
.app-black-button:hover,
.app-black-button:focus {
  color: black;
  border-color: black;
}
.app-brown-btn,
.app-brown-btn:active,
.app-brown-btn:hover,
.app-brown-btn:focus {
  background-color: #967a4d;
  color: white;
  border-color: #967a4d;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  color: black !important;
  background: #f9e6e2 !important;
}
.appColor {
  color: #967a4d;
}
.common-layout .page-container,
.nav-wrap,
body {
  background-color: #f3f3f3 !important;
}
.common-layout .page-container .main-content {
  padding: 0 25px 25px 25px;
  min-height: calc(100vh - 65px);
}
.darkGreen {
  color: #1e7b7e !important;
}
.uploadOptions div button,
.uploadOptions div i,
.uploadOptions div span {
  font-size: 12px;
}
.uploadOptions div button:hover {
  color: #967a4d;
}
.linkHover {
  color: black;
}
.linkHover:hover {
  color: #967a4d !important;
}

.info-card:hover {
  border: 1px solid #5f5c5c;
  border-radius: 4px;
  box-shadow: 0 18px 18px rgba(71, 69, 69, 0.05);
  cursor: pointer;


  .title{
    color: rgb(201, 181, 70) !important;
  }


}


// card transision to mark as hovered

.card:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.gold{
  background-color: #967a4d !important;
  color: white !important;
}

.sif-box{

  @media (min-width: 768px) {
height:600px !important;
flex-wrap: wrap;
position: relative;
  }


}

.action-btns{

  // media above mobile devices

  @media (min-width: 768px) {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0;
    padding: 15px;
  }
}
.app-brown-loader .ant-spin-dot i{
  background-color : #967a4d
}